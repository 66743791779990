@font-face {
    font-family: 'Titillium Web';
    src: url("./assets/AMSS-Fonts/Titillium_Web/TitilliumWeb-Regular.ttf");
}

* {
    font-family: 'Titillium Web';
}

body p {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    padding-bottom: 24px;
}

h2 {
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    font-size: 64px;
    font-weight: 700;
    line-height: 77px;
}
h3 {
    font-size: 40px;
    letter-spacing: 0.01em;
}
h5 {
    font-weight: 700;
}
h4 {
    font-weight: 700;
    font-size: 24px;
}
.main-wrapper.black {
    background-color: #282C2D;
    color: #fff;
}
.main-wrapper.black .common-text {
    color: white;
}
.btn {
    padding: 12px 24px 12px 24px;
    border-radius: 4px;
    font-weight: 700;
    font-size: 18px;
}
.btn-narrow {
    padding-left: 20px;
    padding-right: 20px;
}
.btn-outline-success {
    border: 2px solid #009696;
    color: #009696;
}
.btn-success,
.btn-outline-success:hover {
    background: #009696;
    color: white;
}
.btn-success:hover {
    background: #009696;
    color: white;
}
label {
    margin-bottom: 10px;
}
.form-control {
    border: 0;
    border-bottom: 1px solid #838383;
    outline: 0;
    border-radius: 0;
    padding-left: 0;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.01em;
}
.main-wrapper.black .form-control {
    background: #282C2D;
}
textarea {
    overflow: hidden;
    resize: none;
}
.genral-text {
    width: 359px;
    height: 196px;
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.01em;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 1;

}
.main_container {
    background: url("./assets/Grafiken_Matrix/hero.svg");
    background-repeat: no-repeat;
    background-position: 20px -450px;
    background-size: cover;
    width: 100%;
}
.container_overlay {
    background: rgba(255,255,255, 0.7);
}

.center_container {
    position: absolute;
    top: 98rem;
    left: -20rem;
    z-index: -1;
    background: url("./assets/Grafiken_Matrix/center.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 100%;
    min-height: 2000px;
    opacity: 0.3;
}

.bottom_bg_container {
    position: relative;
 } 

.bottom_container {
    background: url("./assets/Grafiken_Matrix/bottom.svg");
    background-repeat: no-repeat;
    background-position: 0 500px;
    background-size: contain;
    width: 100%;
}

@media only screen and (max-width: 768px) {
    .common-text {
        font-size: 28px;
        line-height: 33px;
    }
    .bottom_container {
        background: url("./assets/Grafiken_Matrix/bottom.svg");
        background-repeat: no-repeat;
        background-position: -19px 657px;
        width: 100%;
}
.main_container {
    background: url("./assets/Grafiken_Matrix/hero.svg");
    background-repeat: no-repeat;
    background-position: -180px -190px;
    background-size: cover;
    width: 100%;
}
.center_container {
   left: 6px;
    background: url("./assets/Grafiken_Matrix/center.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
   
}
}
