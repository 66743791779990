
.lowerText {
    /* Auto layout */

display: flex;
flex-direction: row;
align-items: flex-start;
padding: 0px;
gap: 24px;
padding-top: 60px;
width: 841px;
height: 215px;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
}