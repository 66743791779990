.contact-us .contact-spacer, .contact-us .btn {
  margin-bottom: 20px;
}
.resizeable {
  resize: vertical;
  min-height: 50px;
  max-height: 400px;
}

.resizeable:focus {
  height: 150px;
}