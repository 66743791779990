.customBtn {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    gap: 8px;
    width: 125px;
    height: 48px;
    color: #009696;
    border: 2px solid #009696;
    border-radius: 4px;
    background: none;
    flex: none;
    order: 2;
    flex-grow: 0;
}
.customBtn>label {
    width: 85px;
    height: 24px;
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1px;
    color: #009696;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.paddingTopBottom {
    padding-bottom: 24px;
}
.slick-dots {
  bottom: -80px !important;
}
.slick-prev:before,
.slick-next:before {
  color: #009696!important /* or another visible color */
}
.slick-prev {
    left: -50px !important;
}

.jobCol {
    padding-right: 50px;
}
