.header h1 {
    font-size: 96px;
    font-weight: 700;
    margin-bottom: 40px;
    line-height: 115.2px;
}

.header p {
    font-size: 20px;
    color: #838383;
    line-height: 24px;
    font-weight: 700;
    padding: 0;
}

.header>.btn {
    font-size: 24px;
}

@media only screen and (max-width: 768px) {
    #home {
        padding-top: 15rem;
    }
    .header h1 {
        font-size: 40px;
        line-height: 48px;
    }

    .header {
        margin-bottom: 160px;
    }
}