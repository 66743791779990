.navbar {
    padding: 0;
    backdrop-filter: blur(3px);
    transition: 0.6s;
}

.navbar * {
    transition: 0.6s;
}

.navbar.navbar-scrolled {
    background: rgba(255, 255, 255, 0.7);
    height: 60px;
}

.navbar.navbar-scrolled .logo {
    width: 105px;
    /* width: 130px; */
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding: 0 3px;
    white-space: nowrap;
}

.navbar-nav .nav-link {
    font-size: 18px;
    color: #282C2D;
}

.navbar-nav .nav-link:hover {
    font-weight: 700;
}

#langNav > .nav-link.active {
    font-weight: 700;
}

.navbar-nav .nav-link::after {
    display: block;
    content: attr(title);
    font-weight: 700;
    height: 1px;
    color: transparent;
    overflow: hidden;
    visibility: hidden;
}

.navbar-nav.gap {
    gap: 23px;
}

.navbar-brand {
    margin-right: 0;
}

.logo {
    width: 200px;
}

.navbar_container {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    height: 120px;
    width: 100%;
    backdrop-filter: blur(3px);
}

.header_container .navbar {
    height: 120px;
}

.header_container.sticky .navbar {
    height: 80px;
}

.navbar-collapse.collapse.show {
    background: #fff;

}

.navbar-toggler {
    border: none;
}

@media screen and (max-width: 575px) {
    .header_container {
        padding: 0rem 1rem;
    }

    .navbar-collapse {
        padding-left: 2rem;
    }

    .navbar-nav.gap {
        gap: 10px;
    }

    .logo {
        width: 130px;
    }

    .navbar.navbar-scrolled .logo {
        width: 105px;
    }


}


@media screen and (max-width: 990px) {

    .navbar-collapse {
        position: absolute;
        left: 0;
        top: 100px;
        width: 100%;
        z-index: 1;
        text-align: center;
    }

}

@media screen and (max-width: 1250px) {
    .header_container {
        padding: 0rem 1rem;
    }

    /* .navbar-nav {
        margin-left: 2rem;
    } */
}