
.modal-header {
  border-bottom: none !important;
}
.modal-content {
  
}
.modal-body {
  padding: 0 56px 56px;
}
.modalTitle {
  margin-top: 0;

}
.btn-close {
  position: absolute;
  right: 40px;
  top: 40px;
  z-index: 1;
}
.modalTitle > small {
font-weight: 400;
font-size: 24px;
line-height: 150%;
/* or 36px */

letter-spacing: 0.01em;

/* Grey 600 */

color: #282C2D;
}
.modal-heading {
  color: #838383;
  letter-spacing: 0.02em;
text-transform: uppercase;
font-weight: 700;
font-size: 20px;
line-height: 24px;
}

  
  .title {
    position: absolute;
    height: 19.65px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #838383;
  }

  @media only screen and (max-width: 768px) {
    .modal-content {
      width: 370px;
    }
    

}
@media only screen and (min-width: 1000px) {
  .modal-content {
    min-width: 900px;
  }
  

}