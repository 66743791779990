.footer {
    padding: 15px 0;
}
.footer > div > ul {
    margin: 0;
    padding: 0;
}
.footer > div > ul > li {
    list-style: none;
    font-size: 18px;
    font-weight: 400;
    padding-top: 8px;

}
.footer .about {
    border-bottom: 1px solid #838383;
}
.social-icons {
    padding-top: 16px;
    gap: 1rem;
    display: flex;
}
.footerBlur {
    backdrop-filter: blur(3px);
}
.social-icons svg {
    fill: black;
  }
  
  .social-icons a {
    text-decoration: none;
  }
  
  .social-icons a:hover svg {
    fill: black;
  }
/* @media only screen and (max-width: 768px) {
    .footerBlur {
        backdrop-filter: blur(3px);
    }
} */