/*  All the heaing css */
.common-heading {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: #009696;
    margin: 0;
    padding: 0;
}
/*  All the subheadings css */
.common-text {
font-weight: 700;
font-size: 64px;
line-height: 120%;
letter-spacing: 0.01em;
color: #282C2D;
margin-bottom: 80px;
padding-top: 8px;
}
@media only screen and (max-width: 768px) {
    .common-heading {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        text-transform: uppercase;
        color: #009696;
        margin: 0;
    }
    /*  All the subheadings css */
    .common-text {
        font-weight: 700;
        font-size: 40px;
        color: #282C2D;
        margin-bottom: 0px;
        
    }
}
