.small-wrapper {
    padding-top: 268px;
}

.small-wrapper.footer {
    margin-top: 200px;
  
}

@media only screen and (max-width: 768px) {
    .small-wrapper {
        padding-top: 80px;
    }
    .small-wrapper.footer {
        margin-top: 0px
      
    }
}