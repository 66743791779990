.para {
    
        width: 794px;
        height: 24px;
        font-family: 'Titillium Web';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        display: flex;
        align-items: center;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #009696;
       
}

.col {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 96px;
    width: 814px;
    height: 224px;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}